<script>
import Layout from "@/router/layouts/auth";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      membership: null,
      order: null,
    };
  },
  methods: {
    getMembership() {
      api
        .get('memberships')
        .then(response => {
          if (response.data.status == 'success') {
            this.membership = response.data.membership;

            if (this.membership.status == 'approved') {
              this.$router.push("/");
            }

            this.getOrder();
          }
        })
    },
    getOrder() {
      if (this.membership.order.id) {
        api
          .get("store/orders/" + this.membership.order.id)
          .then((response) => {
            if (response.data.status == "success") {
              this.order = response.data.order;
            }
          })
      }
    },
  },
  mounted() {

    this.getMembership();

    setInterval(() => {
      this.getMembership();
    }, 30000);
  },
};
</script>

<template>
  <Layout>
    <div id="auth" class="d-flex">
      <div class="login-content">
        <div class="pt-5 text-center">
          <img class="w-100" style="max-width: 300px" src="@/assets/images/logo.png" />
        </div>
        <div class="py-5">
          <div v-if="!order" class="text-center">
            <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
          </div>
          <div v-else>
            <h3 class="mb-4">Seja bem-vindo à<br>Líder Leads! 🚀</h3>
            <h5 class="mb-4">Finalize sua adesão e comece agora a transformar sua jornada no digital!</h5>
            <h6 class="mb-4">Pedido no. {{ order.id }}</h6>
            <h6 class="mb-1">Taxa de Adesão</h6>
            <h3>{{ order.total | currency }}</h3>
            <hr />
            <h3 class="font-size-16 mt-4 mb-2">{{ t("Status") }}</h3>
            <div v-for="(td, index) in order.status" :key="index">
              {{ td.date }} — {{ t(td.status) }}
            </div>
            <b-tabs v-if="order.payment.status === 'pending'" justified nav-class="nav-tabs-custom mt-4">
              <b-tab v-for="(pay, method) in order.payment.methods" :key="method">
                <template v-slot:title>{{ pay.name }}</template>
                <div v-if="method == 'pix'" class="py-3">
                  <div v-if="pay.pix && pay.pix.text">
                    <div v-if="pay.pix.image">
                      <div>
                        Use o código QR para prosseguir com a transação.
                      </div>
                      <div class="my-4 d-flex justify-content-center">
                        <div class="border rounded p-3">
                          <img style="width: 180px" :src="pay.pix.image" />
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div>
                        Use o Pix Copia e Cola para prosseguir com a transação.
                      </div>
                      <div class="my-4">
                        <fieldset class="text-break py-3">{{ pay.pix.text }}</fieldset>
                      </div>
                    </div>
                    <div>
                      <button class="btn btn-success btn-lg btn-block text-uppercase" v-clipboard:copy="pay.pix.text"
                        v-on:click="$toast.success('<strong>Copiado para a área de transferência.</strong><br>Agora, tudo o que você precisa fazer é acessar o aplicativo do seu banco e concluir o processo usando o <strong>Pix Copia e Cola</strong>.')">
                        Copiar Pix Copia e Cola
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else-if="method === 'usdt'" class="py-3">
                  <p>
                    Envie exatamente o valor para realizar o pagamento<br>
                    <small v-if="pay.expiration"><br>Expira em {{ pay.expiration }}</small>
                  </p>
                  <div class="mt-0 mb-4 text-center">
                    <small class="text-uppercase">Valor</small><br>
                    <fieldset class="font-size-20 mb-1 py-2">{{ pay.total }}</fieldset>
                    <button class="btn btn-outline-success btn-lg btn-block text-uppercase" v-clipboard:copy="pay.total" v-on:click="$toast.success('O valor foi copiado!')">
                      Copiar
                    </button>
                  </div>
                  <div class="mt-2 mb-0 text-center">
                    <small class="text-uppercase">Carteira</small><br>
                    <fieldset class="font-size-16 mb-1 text-break py-2">{{ pay.address }}</fieldset>
                    <button class="btn btn-outline-success btn-lg btn-block text-uppercase" v-clipboard:copy="pay.address" v-on:click="$toast.success('O endereço da carteira foi copiado!')">
                      Copiar
                    </button>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
            <hr />
            <router-link tag="a" to="/login" class="btn btn-outline-dark btn-lg btn-block mt-5">
              Acessar Outra Conta
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>